















































import { Component, Vue } from 'vue-property-decorator';
import './scss/PopupChangePassword.scss';
import { namespace } from 'vuex-class';
import { UserActionTypes } from '@store/modules/user/Types';

const NSUser = namespace('storeUser');

@Component({
    name: 'PopupChangePassword',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
    },
})
export default class PopupChangePassword extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        textField: HTMLFormElement,
    }

    @NSUser.Action(UserActionTypes.A_CHANGE_PASSWORD) changePassword!: (payload) => Promise<void>

    btnDisabled: boolean = false;
    realtimeValidation: boolean = false;

    fields = {
        passwordOld: '',
        passwordNew: '',
        passwordNewConfirm: '',
    }

    errors = {
        passwordOld: false,
        passwordNew: false,
        passwordNewConfirm: false,
        empty: false,
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get errorText() {
        if ((this.fields.passwordNew.length || this.fields.passwordNewConfirm.length)
            && this.fields.passwordNew.length !== this.fields.passwordNewConfirm.length) {
            return 'Введенные пароли отличаются';
        }
        if ((this.fields.passwordNew.length || this.fields.passwordNewConfirm.length)
            && this.fields.passwordNew.length === this.fields.passwordNewConfirm.length
            && this.fields.passwordNew === this.fields.passwordOld
        ) {
            return 'Новый пароль должен отличаться от старого';
        }
        if (this.errors.passwordOld) {
            return 'При отправке запроса возникла ошибка';
        }
        return 'Вы не заполнили одно или несколько обязательных полей';
    }

    onSuccess() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;
            this.changePassword({
                password: this.fields.passwordOld,
                newPassword: this.fields.passwordNew,
            }).then(() => {
                this.onSuccessChangePassword();
                this.closePopup();
            }).catch(() => {
                this.errors.passwordOld = true;
            }).finally(() => {
                this.btnDisabled = false;
            });
        } else {
            this.realtimeValidation = true;
        }
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    validateFields() {
        this.errors.passwordOld = false;
        this.errors.empty = Object.values(this.fields).some((item) => !item.length);
        this.errors.passwordNew = this.fields.passwordNew.length != 0
            && (this.fields.passwordNew !== this.fields.passwordNewConfirm
                || (this.fields.passwordNew === this.fields.passwordNewConfirm
                    && this.fields.passwordNew === this.fields.passwordOld));
        this.errors.passwordNewConfirm = this.fields.passwordNewConfirm.length != 0
            && (this.fields.passwordNew !== this.fields.passwordNewConfirm);

    }

    emitClose() {
        this.$emit('close');
    }

    emitConfirm() {
        this.$emit('confirm');
    }

    openPopup() {
        this.$refs.basePopup.openPopup();
        if (this.$refs.textField) {
            this.$refs.textField.focusInput();
        }
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
        this.resetFields();
    }

    resetFields() {
        this.fields = {
            passwordOld: '',
            passwordNew: '',
            passwordNewConfirm: '',
        }
        this.errors = {
            passwordOld: false,
            passwordNew: false,
            passwordNewConfirm: false,
            empty: false,
        }
        this.realtimeValidation = false;
    }

    onSuccessChangePassword() {
        this.$emit('success-change-password');
        this.resetFields();
    }
}
